let vpnStatus: boolean | null = null;
let detectionPromise: Promise<boolean> | null = null;

export function detectVPN(): Promise<boolean> {
  if (vpnStatus !== null) {
    return Promise.resolve(vpnStatus); // Если уже проверили, возвращаем результат
  }

  if (!detectionPromise) {
    detectionPromise = new Promise((resolve) => {
      const rtc = new RTCPeerConnection({
        iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
      });

      rtc.createDataChannel("");
      rtc.createOffer().then((offer) => rtc.setLocalDescription(offer));

      rtc.onicecandidate = (event) => {
        if (event.candidate) {
          const candidate = event.candidate.candidate;
          if (candidate.includes("relay") || candidate.includes("srflx")) {
            vpnStatus = true; // VPN обнаружен
            resolve(true);
          }
        }
      };

      setTimeout(() => {
        vpnStatus = false; // Если таймаут, значит VPN нет
        resolve(false);
      }, 3000);
    });
  }

  return detectionPromise.then((result) => {
    vpnStatus = result;
    return result;
  });
}
