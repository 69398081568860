import type { EnrichmentPlugin, Event } from "@amplitude/analytics-types";
import { getLocaleFromUrl } from "src/utils/helpers";

export const customEvents = {
  VIEW_PAGE: "View Page",
  BUTTON_CLICK: "Button Click",
  VIEW_SECTION: "View Section",
  ORDER_START: "Order Start",
  MEDUSA_FAIL: "medusa_fail",
  PRODUCT_LOAD_ERROR: "product_load_error",
  SUBMIT_SHIPPING_DATA: "submit_shipping_data",
  PAY_START: "pay_start",
  SUBMIT_EMAIL: "Submit Email",
  LANGUAGE_CHANGED: "change-language",
  BLOG_SCROLLED: "blog-scrolled",
};

const checkCustomPageName = () =>
  document.querySelector("[data-amp-page-name]")?.dataset?.ampPageName;

export const getPageNameByPath = (withLocale = true): string => {
  const pathParts = window.location.pathname.split("/").filter(Boolean);
  if (withLocale) {
    return pathParts.length > 1 ? pathParts[1] : "main";
  }
  return pathParts[0] ?? "main";
};

const transform: { [key: string]: (event: Event) => Event | null } = {
  "[Amplitude] Page Viewed": (event: Event) => {
    event.event_type = customEvents.VIEW_PAGE;
    event.event_properties = {
      ...event.event_properties,
      page_name:
        checkCustomPageName() || checkIfCobrand() || getPageNameByPath(),
      language: getLocaleFromUrl(),
    };
    return event;
  },
  "[Amplitude] Element Clicked": (event: Event) => {
    if (
      !Object.prototype.hasOwnProperty.call(
        event.event_properties!,
        "[Amplitude] Element Attributes",
      )
    ) {
      return null;
    }
    event.event_type = customEvents.BUTTON_CLICK;
    event.event_properties = {
      ...event.event_properties,
    };
    return event;
  },
};

const checkIfCobrand = () => {
  const pathParts = window.location.pathname.split("/").filter(Boolean);
  const hostnamePaths = window.location.hostname.split(".");

  const cobrandName = hostnamePaths.length === 3 ? hostnamePaths[0] : undefined;
  return pathParts[0] === "subdomains" ? pathParts[1] : cobrandName;
};

export const pageViewTrackingEnrichment = (): EnrichmentPlugin => {
  return {
    name: "page-view-tracking-enrichment",
    type: "enrichment",
    setup: async () => undefined,
    execute: async (event) => {
      return transform[event.event_type]
        ? transform[event.event_type](event)
        : event;
    },
  };
};
